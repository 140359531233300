@import './croppie';
@import './variables';
@import './mixins';
@import './shepherd';
@import './angular-material';


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $default-font-family;
  color: #333333;
  background-color: #f7f7f7;
}

mat-row {
  margin-top: 10px;
  box-shadow: 0 2px 10px 0 rgba(226, 235, 255, 0.8);
  background-color: #ffffff;
}

mat-footer-row, mat-header-row, mat-row {
  border-style: none !important;
}

mat-header-row {
  min-height: 0 !important;
}

.mat-sort-header-button {
  min-height: 0 !important;
  font-family: Lato, sans-serif !important;
  font-size: 14px !important;
  font-weight: 900 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.36 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #808495 !important;
}

.mat-tooltip {
  font-size: 14px !important;
}

.tooltip {
  font-size: 14px;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.x-hellosign-embedded--in-modal {
  z-index: 1000;
}

body div#hubspot-messages-iframe-container {
  margin-right: 20px !important;

  @media screen and (max-width: 600px) {
    margin-right: 0 !important;
  }
}

.table-placeholder-empty {
  display: flex;
  justify-content: center;
  padding: 24px 0;
  align-items: center;
  gap: 10px
}

.loading-button .mat-button-wrapper {
  visibility: hidden;
}

.loading-button .mat-progress-spinner {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

.app-text-limit-one-word {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.app-text-limit-multiple-words {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.app-hide-elements {
  display: none !important;
}
