.shepherd-element {
  .shepherd-content {
    background-color: #FEC002;
    padding: 10px;
  }

  .shepherd-text {
    font-family: Lato, sans-serif;
    font-size: 24px;
    color: #000000;
  }

  .shepherd-arrow:before {
    background-color: #FEC002;
  }

  .shepherd-footer {
    justify-content: initial;
  }

  .shepherd-button {
    background-color: #FFFFFF;
    width: 240px;
    font-size: 16px;
    color: #00257D;
  }

  &.with-stepper .shepherd-header {
    padding: 0;
  }

  &.with-stepper .shepherd-cancel-icon {
    position: absolute;
    top: 24px;
    left: 220px;

    span {
      display: none;
    }
  }

  &.with-stepper .shepherd-cancel-icon::before {
    content: 'Skip';
    font-size: 16px;
    color: #000000;
    text-decoration: underline;
  }
}


.stepper-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.stepper-steps-container {
  position: relative;
}

.stepper-steps-line {
  position: absolute;
  width: 150px;
  border: 1px solid #000000;
  top: 20px;
  left: 10px;
}

.stepper-step-number {
  border: 1px solid #000000;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 3px 7px;
  margin-right: 24px;
  background-color: #FEC002;
  position: relative;
  z-index: 1;
  user-select: none;
}

.stepper-step-number-selected {
  background-color: #FFFFFF;
  border-radius: 0;
}
