@mixin responsive-font($base-width, $base-font-size) {
  .responsive-font {
    font-size: $base-font-size;
  }
}

@mixin mobile-only($visibility) {
  .mobile-only {
    visibility: $visibility;
  }
}
@mixin desktop-only($visibility) {
  .desktop-only {
    visibility: $visibility;
  }
}

@media screen and (max-width: 1000px) {
  @include responsive-font($base-width: 960px, $base-font-size: 8px);
  @include mobile-only($visibility: visible);
  @include desktop-only($visibility: hidden);
}

@media screen and (min-width: 1001px) {
  @include responsive-font($base-width: 1160px, $base-font-size: 18px);
  @include mobile-only($visibility: hidden);
  @include desktop-only($visibility: visible);
}

@mixin not-last-child($selector) {
  & #{$selector}:not(:last-child) {
    @content;
  }
}
